// Color Palette Variables
$off-white: #F5F5F5;
$slate-gray: #708090;
$tiffany-blue: #0ABAB5;
$bright-coral: #FF6F61;
$desert-rose: #C08081;
$silver: #C0C0C0;

// Import Google Fonts (Poppins for a modern look)
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

// Reset and Base Styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: $off-white;
  color: $slate-gray;
  line-height: 1.6;
  overflow: hidden; // Prevent scrolling on the body
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1rem;
  overflow: hidden;
  background: linear-gradient(135deg, $off-white 0%, #e0e0e0 50%, $slate-gray 100%); // Subtle gradient
  background-size: 200% 200%;
  animation: gradientShift 15s ease infinite;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// Header Styles
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  .profile-img {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
    border: 3px solid $silver;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: $slate-gray;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.2rem;
    font-weight: 300;
    color: $slate-gray;
    max-width: 90%;
  }
}

// Links Section
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  max-width: 25rem;
  flex-grow: 1;
  justify-content: center;
}

.link-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 20rem;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  color: $slate-gray;
  background: rgba(255, 255, 255, 0.2); // Semi-transparent white for glass effect
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3); // Subtle border
  backdrop-filter: blur(10px); // Frosted glass blur effect
  -webkit-backdrop-filter: blur(10px); // For Safari support
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); // Subtle shadow
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    background: rgba(255, 255, 255, 0.3); // Slightly more opaque on hover
    color: $off-white;
  }

  &:focus {
    outline: 2px solid $slate-gray;
    outline-offset: 2px;
  }

  .link-icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    flex: 1;
    text-align: left;
    line-height: 2rem;
  }

  // Brand-specific hover effects
  &.mmm-button:hover {
    background: rgba(10, 186, 181, 0.3); // Tiffany Blue with transparency
    border-color: $tiffany-blue;
    color: $off-white;
  }

  &.instagram-button:hover {
    background: linear-gradient(45deg, rgba(240, 148, 51, 0.3), rgba(188, 24, 136, 0.3)); // Instagram gradient
    border-color: #bc1888;
    color: $off-white;
  }

  &.x-button:hover {
    background: rgba(0, 0, 0, 0.3); // Black with transparency
    border-color: #000000;
    color: $off-white;
  }
}

// Footer Styles
.footer {
  padding: 0.5rem;
  font-size: 0.9rem;
  color: $slate-gray;
  margin-top: 1rem;
}

// Responsive Design with Media Queries

// Small Devices (Phones, Portrait and Landscape, up to 576px)
@media (max-width: 576px) {
  .App {
    padding: 0.5rem;
  }

  .header {
    .profile-img {
      width: 8rem;
      height: 8rem;
    }

    h1 {
      font-size: 1.8rem;
    }

    p {
      font-size: 0.9rem;
    }
  }

  .links {
    gap: 0.5rem;
  }

  .link-button {
    max-width: 18rem;
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;

    .link-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.8rem;
    }

    span {
      line-height: 1.5rem;
    }
  }

  .footer {
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
}

// Medium Devices (Tablets, Portrait, 577px to 768px)
@media (min-width: 577px) and (max-width: 768px) {
  .App {
    padding: 1rem;
  }

  .header {
    .profile-img {
      width: 10rem;
      height: 10rem;
    }

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .links {
    gap: 0.75rem;
  }

  .link-button {
    max-width: 20rem;
    padding: 0.6rem 0.9rem;
    font-size: 1rem;

    .link-icon {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 0.9rem;
    }

    span {
      line-height: 1.8rem;
    }
  }

  .footer {
    font-size: 0.8rem;
  }
}

// Tablets in Landscape (769px to 1024px)
@media (min-width: 769px) and (max-width: 1024px) {
  .App {
    padding: 1.5rem;
  }

  .header {
    .profile-img {
      width: 11rem;
      height: 11rem;
    }

    h1 {
      font-size: 2.2rem;
    }

    p {
      font-size: 1.1rem;
    }
  }

  .links {
    gap: 0.75rem;
  }

  .link-button {
    max-width: 22rem;
    padding: 0.7rem 1rem;
    font-size: 1.1rem;

    .link-icon {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }

    span {
      line-height: 2rem;
    }
  }
}

// Large Devices (Computers, 1025px and up)
@media (min-width: 1025px) {
  .App {
    padding: 2rem;
  }

  .header {
    .profile-img {
      width: 14rem;
      height: 14rem;
    }

    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1.3rem;
    }
  }

  .links {
    gap: 1rem;
  }

  .link-button {
    max-width: 25rem;
    padding: 0.9rem 1.2rem;
    font-size: 1.2rem;

    .link-icon {
      width: 2.2rem;
      height: 2.2rem;
      margin-right: 1.2rem;
    }

    span {
      line-height: 2.2rem;
    }
  }
}

// Specific Adjustments for Landscape Orientation on Phones and Tablets
@media (max-height: 500px) and (orientation: landscape) {
  .App {
    padding: 0.5rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .header {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    flex: 1;

    .profile-img {
      width: 6rem;
      height: 6rem;
      margin-bottom: 0.5rem;
    }

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.8rem;
      max-width: 100%;
    }
  }

  .links {
    flex-direction: column;
    gap: 0.5rem;
    max-width: 15rem;
    flex: 1;
    justify-content: center;
  }

  .link-button {
    max-width: 12rem;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;

    .link-icon {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.5rem;
    }

    span {
      line-height: 1.2rem;
    }
  }

  .footer {
    font-size: 0.6rem;
    margin-top: 0;
    flex: 1;
    display: flex;
    align-items: center;
  }
}
